import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getPermissions } from "../../../actions/dashboardAction";
import { logout } from "../../../actions/userAction";
import { SideBarMenuItems } from "../../../lib/constants/sideBarMenuItems";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../../../lib/constants/utils/token";
import cookieUtils from "../../../lib/constants/utils/cookies";
import {trackEvent } from "../../../matomoScript";


class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = { isSignedOut: false };
    this.signOut = this.signOut.bind(this);
  }

  generateEventName(pageName) {
    const lowerCasePageName = pageName.toLowerCase();
    const formattedPageName = lowerCasePageName.replace(/\s+/g, '_');
    return `click_${formattedPageName}`;
  }
  
  onSideMenuClick(pageName){
    trackEvent(`${this.generateEventName(pageName)}`);
      }

  renderSideBarItems() {
    const { permission } = this.props.sideBarData;
    let active = this.props.location.pathname.split("generic/");

    if (permission) {
      const tabList = SideBarMenuItems.map((item) => item.code);
      const permissionsTabList = Object.keys(permission.pages);
      const mergedTabList = [...new Set(tabList.concat(permissionsTabList))];

      return mergedTabList.map((key, i) => {
        if (permission?.pages[key]?.enabled === 1) {
          return (
            <li
              style={{ textAlign: "left" }}
              className={active[1] === key ? `active` : null}
              key={key}
              onClick={()=>this.onSideMenuClick(permission?.pages[key].text)}
            >
              <Link to={`/generic/${key}`} className="sidebar-link-item">
                <img
                  src={`${permission.pages[key].icon}`}
                  alt="."
                  height="17"
                  style={{
                    display: "inline-block",
                    verticalAlign: "text-bottom",
                    paddingRight: "4px",
                  }}
                />
                <span style={{ color: "white" }}>
                  {" " + permission.pages[key].text}
                </span>
              </Link>
            </li>
          );
        } else {
          return [];
        }
      });
    }
  }

  async signOut() {
    window.Intercom("shutdown");

    let accessToken = {Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`};
    let refreshToken = cookieUtils.get(REFRESH_TOKEN_KEY);
    await this.props.logout(accessToken, {refresh_token: refreshToken});
    localStorage.clear();
    cookieUtils.removeAllCookies();
    this.setState({ isSignedOut: true });
  }

  render() {
    if (this.state.isSignedOut === true) {
      return <Redirect to="/" />;
    }

    return (
      <aside className="main-sidebar" style={{ backgroundColor: "#000" }}>
        <section className="sidebar">
          <div className="user-panel" style={{ paddingBottom: "5%" }}>
            <div className="info" style={{ left: "0%" }} />
          </div>
          <ul className="sidebar-menu" data-widget="tree">
            {this.props.sideBarData ? this.renderSideBarItems() : null}
            <div
              className={`side-bar-info ${
                this.props.sideBarData?.permission?.pages
                  ? ""
                  : "side-bar-info-fixed"
              }`}
            >
              GV: 7c5f59d5
            </div>
          </ul>
        </section>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  sideBarData: state.dashboardReducer,
});

const mapDispatchToProps = {
  logout,
  getPermissions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
